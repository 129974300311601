.carousel-v1_0 {
  @import "../shared/_defaults";
  @include display-header;
  @include origin-flag-tagboard-classic;

  .display-header{
    .qr-module{
      height: 90%!important;
    }
  }

  .right .display-wrapper {
    left: auto;
    right: 0;
  }

  .lowerthird .display-wrapper {
    top: auto;
    bottom: 0;
  }

  .carousel-anim-enter {
    transform: translate(0, 100%);
  }

  .post-container {
    transition: transform 800ms ease;
    will-change: transform;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .carousel-overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;

    .left,
    .right {
      position: absolute;
      top: 0;
      width: 16%;
      height: 100%;
    }

    .left {
      left: 0;
      background: linear-gradient(to right, rgba($black, .85), rgba($black, 0));
    }

    .right {
      right: 0;
      background: linear-gradient(to left, rgba($black, .85), rgba($black, 0));
    }

    .top,
    .bottom {
      position: absolute;
      left: 0;
      width: 100%;
      height: 16%;
    }

    .top {
      top: 0;
      background: linear-gradient(to bottom, rgba($black, .85), rgba($black, 0));
    }

    .bottom {
      bottom: 0;
      background: linear-gradient(to top, rgba($black, .85), rgba($black, 0));
    }
  }

  .horizontal {
    perspective: 1920px;

    .carousel-anim-enter {
      transform: translate(-100%, 0);
    }

    &.sidebar .posts {
      top: 15vmin;
      bottom: 0;
      left: -50%;
      right: auto;
      margin: auto;
      width: 200%;
      height: 80%;
      perspective: 1920px;
      perspective-origin: 50% 50%;
    }

    .posts {
      top: 0;
      bottom: 0;
      left: -50%;
      right: auto;
      margin: auto;
      width: 200%;
      height: 70%;
      perspective: 1920px;
      perspective-origin: 50% 50%;
    }

    .post-container {
      position: relative;
      display: inline-block;
      width: 30%;
      height: 100%;

      &:nth-child(2) {
        margin: 0 5%;
      }

      &.carousel-anim-leave-active {
        position: absolute;
      }
    }

    &.lowerthird .post-container {
      width: 32%;

      &:nth-child(2) {
        margin: 0 2%;
      }
    }

    .Scale {
      transform-style: preserve-3d;

      .post {
        transition: transform 800ms ease;
      }

      &:nth-child(1) .post {
        transform: scale(0.75);
      }

      &:nth-child(2) .post {
        transform: scale(1);
      }

      &:nth-child(3) .post,
      &:nth-child(4) .post {
        transform: scale(0.75);
      }
    }

    .Flow {
      transform-style: preserve-3d;
      z-index: 2;

      .post {
        will-change: transform;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        transition: transform 800ms ease;
      }

      &:nth-child(1) .post {
        transform: translate(20%, 0) rotate3d(0, 1, 0, 55deg);
      }

      &:nth-child(2) {
        z-index: 10;

        .post {
          transform: rotate3d(0, 1, 0, 0deg);
        }
      }

      &:nth-child(3) .post,
      &:nth-child(4) .post {
        transform: translate(-20%, 0) rotate3d(0, 1, 0, -55deg);
      }

      &:nth-child(4) {
        z-index: 1;
      }
    }
  }

  .vertical {
    perspective: 1920px;

    &.sidebar.cta-template-5 .posts,
    &.sidebar.cta-template-6 .posts {
      top: 12vmin;
      bottom: auto;
      left: 0;
      right: 0;
      margin: auto;
      width: 70%;
      height: 100%;
      display: flex;
      flex-direction: column-reverse;
      perspective-origin: 50% 50%;
      perspective: 1920px;
    }

    .posts {
      top: -50%;
      bottom: auto;
      left: 0;
      right: 0;
      margin: auto;
      width: 70%;
      height: 200%;
      display: flex;
      flex-direction: column-reverse;
      perspective-origin: 50% 50%;
      perspective: 1920px;
    }

    .post-container {
      position: relative;
      width: 100%;
      height: 30%;

      &:nth-child(2) {
        margin: 5% 0;
      }

      &.carousel-anim-leave-active {
        position: absolute;
        top: 0;
        transform: translateY(-100%);
      }
    }

    &.lowerthird .post-container {
      height: 32%;

      &:nth-child(2) {
        margin: 2% 0;
      }
    }

    .Scale {

      .post {
        transition: transform 800ms ease;
      }

      &:nth-child(1) .post {
        transform: scale(0.75);
      }

      &:nth-child(2) .post {
        transform: scale(1);
      }

      &:nth-child(3) .post,
      &:nth-child(4) .post {
        transform: scale(0.75);
      }
    }

    .Flow {
      transform-style: preserve-3d;

      .post {
        transition: transform 800ms ease;
      }

      &:nth-child(1) .post {
        transform: rotate3d(1, 0, 0, 55deg);
      }

      &:nth-child(2) .post {
        transform: rotate3d(1, 0, 0, 0deg);
      }

      &:nth-child(3) .post,
      &:nth-child(4) .post {
        transform: rotate3d(1, 0, 0, -55deg);
      }
    }
  }

  .post {
    font-size: 1em;
    position: absolute;
    width: 100%;
    height: 100%;
    background: rgba($black, .5);
    color: $dark-gray;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }

  .media-module {
    position: relative;
    width: 50%;
    height: 100%;
    float: left;
    background-position: 50% 50%;
    background-size: contain;
    background-repeat: no-repeat;
    overflow: hidden;
  }

  .blurred-media-module {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 50%;
    height: 100%;
    overflow: hidden;
  }

  .lowerthird {
    .media-module,
    .blurred-media-module {
      width: 20%;
    }
  }

  .media {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
  }

  .text-module {
    position: relative;
    width: 100%;
    height: 100%;
    float: right;
    padding: .5em;
    background-color: $white;
    overflow: hidden;
  }

  .has-media .text-module {
    width: 50%;
    height: 100%;
  }

  .lowerthird .has-media .text-module {
    width: 80%;
  }

  .fullscreen .share-it.portrait-post,
  .sidebar .share-it.portrait-post {
    .media-module {
      float: none;
      margin: 0 auto;
      height: 100%;
    }

    .blurred-media-module {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  .fullscreen .portrait-post,
  .sidebar .portrait-post {
    .blurred-media-module {
      width: 100%;
      height: 60%;
    }

    .media-module {
      float: none;
      width: 100%;
      height: 60%;
    }

    .text-module {
      width: 100%;
      height: 100%;
    }

    &.has-media .text-module {
      float: none;
      width: 100%;
      height: 40%;
    }
  }

  .meta-author {
    position: relative;
    font-size: .8em;
    padding-left: 2.75em;
    padding-right: 1em;
    margin-bottom: .5em;
  }

  .no-avatar {
    .meta-author {
      padding-left: 0;
    }

    .avatar {
      display: none;
    }
  }

  .avatar {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: auto;
    margin: auto;
    width: 2.25em;
    height: 2.25em;
    border-radius: 100%;
    box-shadow: 0 0 4px rgba($black, .5);
    background-position: 50% 50%;
    background-size: cover;
    background-repeat: no-repeat;
  }

  .user-img {
    visibility: hidden;
    width: 100%;
    height: 100%;
  }

  .author,
  .handle {
    @include ellipsis;
    float: left;
    clear: both;
    max-width: 100%;
    line-height: 2.25;
  }

  .twitter,
  .tiktok {
    .author,
    .handle {
      line-height: 1.3;
    }

    .handle {
      font-size: .8em;
    }
  }

  .reddit .subreddit {
    float: left;
    clear: both;
    max-width: 100%;
    line-height: 1.3;
    @include ellipsis;

    + .handle {
      font-size: .8em;
      line-height: 1.3;
    }
  }

  .twitch.has-media,
  .heymarket.has-media {
    .text-module {
      display: none;
    }

    .media-module {
      float: none;
      width: 100%;
      height: 100%;
    }
  }

  .post-time {
    display: none;
    font-size: .8em;
    margin-bottom: .5em;
    @include ellipsis;
  }

  .timestamp {
    font-size: .8em;
  }

  .fullscreen,
  .sidebar {
    .audio-attribution {
      position: absolute;
      width: 100%;
      bottom: 0%;
      left: 0%;
      color: #fff;
      padding: 0.5em 1em;
      font-size: .5em;
      line-height: 1.75;
      text-shadow: 0 0 0.3em #000;
    }

    .audio-avatar {
      display: inline-block;
      width: 1.75em;
      height: 1.75em;
      margin-left: .15em;
      margin-bottom: -.3em;
      border-radius: 100%;
      background-color: transparent;
      background-position: 50% 50%;
      background-size: cover;
      background-repeat: no-repeat;
      animation: spin 8s linear infinite;

      @keyframes spin {
        from {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }

        to {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }
    }

    .audio-info {
      width: calc(50% - 2em);
      display: inline-block;
      margin-left: .15em;
      margin-bottom: -.3em;
      overflow: hidden;
      mask-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 8%, rgba(255, 255, 255, 1) 92%, rgba(255, 255, 255, 0) 100%);
      -webkit-mask-image: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 8%, rgba(255, 255, 255, 1) 92%, rgba(255, 255, 255, 0) 100%);
    }

    .audio-info-inner {
      display: inline-block;
      white-space: nowrap;
      transform: translateX(100%);
      animation: marquee 8x linear infinite;
      -webkit-animation: marquee 8s linear infinite;

      @keyframes marquee {
        0% {
          transform: translateX(0%);
        }

        100% {
          transform: translateX(-33.333333%);
        }
      }

      @-webkit-keyframes marquee {
        0% {
          -webkit-transform: translateX(0%);
        }

        100% {
          -webkit-transform: translateX(-33.333333%);
        }
      }
    }
  }

  .lowerthird {
    .audio-attribution {
      font-size: .6em;
      line-height: 1.5;
      margin-bottom: .5em;
      @include ellipsis;
    }

    .audio-avatar {
      display: none;
      width: 1.5em;
      height: 1.5em;
      margin-left: .15em;
      margin-bottom: -.25em;
      border-radius: 100%;
      background-color: transparent;
      background-position: 50% 50%;
      background-size: cover;
      background-repeat: no-repeat;
      -webkit-animation: spin 8s linear infinite;
      animation: spin 8s linear infinite;

      @-webkit-keyframes spin {
        from {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }

        to {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }

      @keyframes spin {
        from {
          -webkit-transform: rotate(0deg);
          transform: rotate(0deg);
        }

        to {
          -webkit-transform: rotate(360deg);
          transform: rotate(360deg);
        }
      }
    }

    .audio-info {
      margin-left: .15em;
    }
  }

  .post-title {
    margin-bottom: .5em;
    font-weight: bold;
  }

  .origin-flag {
    top: .5em;
    right: -.3em;
  }

  .post-color-theme-dark {
    .post {
      color: $white;
    }

    .text-module {
      background-color: rgba($black, .5);
    }
  }

  .share-it.no-text {
    .media-module {
      float: none;
      margin: 0 auto;
      height: 100%;
    }

    .blurred-media-module {
      width: 100%;
      display: block;
    }
  }
  
  // QR CODE
  .grid-cell .qr-module {
    position: relative;
    width: auto;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .square-placeholder {
    width: auto;
    height: 100%;
    display: none;
  }

  .qr-code {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;

    img{
      display: none!important;
    }

    canvas{
      display: block!important;
    }

    canvas,
    img {
      width:  auto;
      height: 95%;
      max-width: 100%;
      max-height: 100%;
      aspect-ratio: 1 / 1;
      object-fit: contain;
    }
  }
}
